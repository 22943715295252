// PACKAGES
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {auth} from './Firebase';
import {onAuthStateChanged} from "firebase/auth";
import React from 'react';

let uid;
try {
  uid = auth.currentUser.uid;
} catch (error) {
  uid = "unknown";
}
onAuthStateChanged(auth, (user) => {
  if (user) {
    uid = user.uid;
  }
});


// function initBugsnag() {
  Bugsnag.start({
    apiKey: 'f6874c98af0496b48a2ae2f742ee8602',
    appVersion: process.env.REACT_APP_VERSION,
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
        event.setUser(uid);
        event.addMetadata(
          {},
        )
    }
  });
// }


const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default Bugsnag;
export {
  ErrorBoundary,
}
