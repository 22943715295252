import { useEffect, useState } from "react";

export default function Step1IsRedeemed({code, data, isShow, product1, product2, setStep, step}) {
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  useEffect(() => {
    if ( data ) {
      setIsLoading(true);
      //setTimeout(() => {setIsLoading(false)}, 3000);
      if ( data.redeemedDate ) {
        let _date;
        if (data.redeemedDate._seconds ) {
          _date = new Date();
          _date.setTime(data.redeemedDate._seconds * 1000); // setTime expects milliseconds
        } else if (data.redeemedDate) {
          _date = new Date(data.redeemedDate); // set date from string
        }
        if ( _date ) {
          setDate(_date);
        }
      }
      setIsLoading(false);
    }
  },[data])

  useEffect(() => {
    if ( step === 1 ) {
      if ( data && data.isUnclaimed ) {
        if ( product1 && (!data.productId2 || product2) ) {
          setTimeout(() => {setStep(2)}, 2000);
        }
      }
    }
  },[data, product1, product2, setStep, step])

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          Checking redemption status...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step"}>
          {(!data.isUnclaimed) && <div>This reward code was already redeemed by <br/>
            <h3>{data.redeemedEmail}</h3> {(date)? "on" : ""} <h3>{(date) ? month[date.getMonth()] : ""} {(date) ? (date.getDate()) : ""}, {(date) ? date.getFullYear() : ""}.</h3><br/><br/>
            If this wasn't you, please contact the Gizmoplex Support Team now.<br/>
            <br/>
            <a className="button-blue" href={`mailto:kickstarter@mst3k.com?subject=Gizmoplex Code claims it has been redeemed.&body=Please help! The Gizmoplex Reward Center claims that my reward code ${code} was already claimed by ${data.redeemedEmail}. What should I do?`}>Contact Support</a> 
          </div>}
          {data.isUnclaimed && `Available for redemption...`}
          {data.isUnclaimed && <div className="spinner-inline" />}
        </div>
      );
    }
  } else {
    return (<></>);
  }
}