import {useState} from "react";

export default function Step2IsGift({data, isShow, setStep}) {
  const [isGift, setIsGift] = useState(false);
  const [isLoading, ] = useState(false);

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          ...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step"}>
          <div className={!isGift ? "" : "display-none"}>
            Are you redeeming this code for yourself,<br/>
            or is it a gift for another person?<br/><br/>
            <button className="button-blue" onClick={()=>setStep(3)}>FOR MYSELF</button>
            <button className="button-blue" onClick={()=>setIsGift(true)}>FOR SOMEONE ELSE</button>
          </div>
          <div className={isGift ? "" : "display-none"}>
            That's so kind of you! Since this reward is for someone else,<br/>
            please email THEM your reward link, so that they can redeem it themselves!<br/><br/>
            <a 
            className="button-blue display-none" 
            href={
              `mailto:?subject=We've got GIFT SIGN: I got you something in the Gizmoplex!&body=As a gift, I've gotten you the following MST3K product in the Gizmoplex: ${data.packageTitle}<br/> You can claim it right now by using this link: <a href="${window.location.href}">${window.location.href}</a>`
            }>SEND GIFT EMAIL!</a>
          </div>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}