import {auth, functions} from './Firebase';
import {httpsCallable} from "firebase/functions";
import { useState } from "react";

/* Add product to existing account */
export default function Step21ConfirmCreate({code, data, email, emailSubmitted, isShow, setStep}) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    // Set loading
    setIsLoading(true);

    const user = auth.currentUser;
    const idToken = await user.getIdToken();
    const redeemCode = httpsCallable(functions, 'vhxRedeemPromoCode');
    if ( idToken ) {
      const results = await redeemCode({
        "idToken": idToken,
        "code": code,
        "email": email,
        "emailSubmitted": emailSubmitted,
      });
      if ( results ) {
        if ( results.data && results.data.success ) {
          setStep(30); // success
        } else {
          // const error = results.data.error || "";
          setStep(31);
        }
      }
    }
  }

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          <b>Give us just a moment, please.</b><br/>
          We're creating a Gizmoplex account for {emailSubmitted}<br/>
          and redeeming your reward code for {data.packageTitle}.<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step create-account"}>
          We didn't find a Gizmoplex account for <b>{emailSubmitted}</b> so we'll be creating a new one.<br/>
          Do you want to claim your reward(s) <br/>
          and <b>create a new account</b> with <b>{emailSubmitted}</b> now?<br/>
          <br/>
          <button className="button-blue" onClick={handleSubmit}>Yes, create a new account!</button>
          <button className="button-blue" onClick={()=>setStep(20)}>No, use a different email address</button><br/>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}
