import {auth, functions} from "./Firebase";
import {httpsCallable} from "firebase/functions";
import {useEffect, useState} from "react";
import {useAuthState} from 'react-firebase-hooks/auth';

export default function Step0CheckProducts({code, data, isShow, setProduct1, setProduct2, setStep, step}) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, loading, ] = useAuthState(auth); // user, loading,

  async function handleGetProductsInfo() {
    if ( user && data && data.productId1 ) {
      setIsLoading(true);
      const idToken = await user.getIdToken();
      if ( idToken ) {
        const vhxGetProduct1 = httpsCallable(functions, 'vhxGetProduct');
        vhxGetProduct1({
          "idToken": idToken,
          "productId": data.productId1,
        })
            .then((results1) => {
              // results1.data will be null if product not found
              if ( results1 && results1.data ) {
                setProduct1(results1.data);
              } else {
                setIsError(true);
              }
              // If there is a second product id
              if ( data.productId2 ) {
                const vhxGetProduct2 = httpsCallable(functions, 'vhxGetProduct');
                vhxGetProduct2({
                  "idToken": idToken,
                  "productId": data.productId2,
                })
                    .then((results2) => {
                      if ( results2 && results2.data ) {
                        setProduct2(results2.data);
                        setIsError(false);
                      } else {
                        setIsError(true);
                      }
                      setTimeout(() => {setIsLoading(false)}, 500);
                    })
                    .catch((error) => {
                      setIsError(true);
                    });
              } else {
                // If there is no second product then clear loading
                setTimeout(() => {setIsLoading(false)}, 500);
              }
            })
            .catch((error) => {
              setIsError(true);
            })
            .finally(()=> {
              setTimeout(() => {setIsLoading(false)}, 3000);
            });
      }
    }
  }

  useEffect(() => {
    if ( step === 0 ) {
      handleGetProductsInfo();
    }
  },[step]);

  if ( isShow ) {
    if ( isLoading || loading ) {
      return (
        <div className="step">
          Verifying...<div className="spinner-inline" />
        </div>
      )
    } else {
      if ( !isError ) {
        return (
          <div className={"step"}>
            <button className="button-blue" onClick={()=>setStep(1)}>Continue</button>
          </div>
        );
      } else {
        return (
          <div className={"step"}>
            Hmm. We’re having trouble with your reward code.<br/>
            Please use the button below to get help from our support team!<br/><br/><a className="button-blue" href={`mailto:kickstarter@mst3k.com?subject=HELP! Gizmoplex Code ${code} says that products are not valid&body=Problem redeeming Gizmoplex Code ${code}. Product not valid.`}>Contact Support</a>
          </div>
        );
      }
    }
  } else {
    return (<></>)
  }
}