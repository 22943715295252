import {auth, firebaseApp} from "./Firebase";
import {getAnalytics, logEvent} from "firebase/analytics";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import Footer from "./Footer";
import StepNeg1ValidLink from "./Step-1";
import Step0CheckProducts from "./Step0CheckProducts";
import Step1IsRedeemed from "./Step1IsRedeemed";
import Step2IsGift from "./Step2IsGift";
import Step3IsHaveAccount from "./Step3IsHaveAccount";
import Step10ExistingAccount from "./Step10ExistingAccount";
import Step11NotFound from "./Step11NotFound";
import Step12AccountFound from "./Step12AccountFound";
import Step20NewAccount from "./Step20NewAccount";
import Step21ConfirmCreate from "./Step21ConfirmCreate";
import Step22ConfirmCreate from "./Step22ConfirmCreate";
import Step30Success from "./Step30Success";
import Step31ErrorRedeem from "./Step31ErrorRedeem";
import {useAuthState} from 'react-firebase-hooks/auth';
import {useEffect, useState} from "react";
import useQuery from "./hooks/useQuery";
// import useLocalStorage from "./hooks/useLocalStorage";

export default function AuthContext() {
  const [code, setCode] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [data, setData] = useState(null);
  const [emailSubmitted, setEmailSubmitted] = useState(null); // The email address user submits during step 2
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [isLinkValid, setIsLinkValid] = useState(null);
  const [product1, setProduct1] = useState(null); //useLocalStorage("product1", null);
  const [product2, setProduct2] = useState(null); // useLocalStorage("product2", null);
  const query = useQuery(); // query string parameters
  const [step, setStep] = useState(-1);
  const [user, , ] = useAuthState(auth); // user, loading, error
  const [vhxId, setVhxId] = useState(null);

  function handleStartOver() {
    setStep(-1); // Restart at step -1
    setIsLinkValid(null);
    window.location.reload();
  }

  // Verify link once on arrival
  // SIGN IN ANONYMOUSLY: if following special link and email and code check out
  useEffect(() => {
    signInAnonymously(auth)
        .then(() => {
          // Signed in..
          setStep(-1);
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
        });
  },[]);

  // Process the  image urls to get uncropped versions
  // Product 1
  useEffect(() => {
    if ( product1 ) {
      let img;
      try {
        let _img = decodeURIComponent(product1.thumbnail.medium);
        // _img = _img.replaceAll("fit=crop", "");
        img = _img.replaceAll("fm=jpg", "fm=png");
      } catch(err) {
        img = "";
      }
      setImage1(img);
    }
  },[product1]);
  // Product 2
  useEffect(() => {
    if ( product2 ) {
      let img;
      try {
        let _img = decodeURIComponent(product2.thumbnail.medium);
        // _img = _img.replaceAll("fit=crop", "");
        img = _img.replaceAll("fm=jpg", "fm=png");
      } catch(err) {
        img = "";
      }
      setImage2(img);
    }
  },[product2]);

  // Get code
  useEffect(() =>{
    const code = query.get("code");
      if ( code ) {
        setCode(code);
      }
  },[])

  useEffect(() =>{
    console.log("step: " + step);
    if ( firebaseApp ) {
      const analytics = getAnalytics(firebaseApp);
      logEvent(analytics, 'step_view: ' + step);
    }
  },[step])
  
  // On auth state change re-verify unique link
  onAuthStateChanged(auth, (_user) => {
    if (_user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // const uid = user.uid;
      
      if ( currentUser && _user.uid !== currentUser.uid ) {
        setCurrentUser(_user); // Save current user to state
        setStep(-1); // Restart at step -1
      }
    } else {
      // User is signed out
    }
  });

  return (
    <div className="content">
      {/* PERMANENT HEADER */}
      <div className="header">
        <h3>Welcome to the Gizmoplex Reward Center!</h3>
        <div className={(product1 && (product2 || !data.productId2) && step > -1) ? "" : "display-none"}>
          Your code can be redeemed for the product(s) below:<br/>
          <div className="products">
            {/* Product 1 image */}
            <img alt={(product1) ? product1.name : 'product 1 image'} src={(image1) ? image1 : null} />
            {/* Product 2 image */}
            <img alt={(product2) ? product2.name : 'product 2 image'} className={(data && !data.productId2) ? 'display-none': ""} src={(image2) ? image2 : ""} />
          </div>
          <h2>{(data) ? data.packageTitle : ""}</h2>
        </div>
      </div>

      {/* STEP -1: VALID LINK */}
      <StepNeg1ValidLink 
        code={code}
        isLinkValid={isLinkValid}
        isShow={(step === -1)}
        handleStartOver={handleStartOver}
        setCode={setCode}
        setData={setData}
        setIsLinkValid={setIsLinkValid}
        setStep={setStep}
        step={step}
      />

      {/* STEP 0: CHECK PRODUCTS */}
      <Step0CheckProducts
        code={code}
        data={data}
        isShow={(step === 0)}
        setProduct1={setProduct1}
        setProduct2={setProduct2}
        setStep={setStep}
        step={step} />

      {/* STEP 1: CHECK IF PREVIOUSLY REDEEMED */}
      <Step1IsRedeemed
        code={code}
        data={data}
        isShow={(step === 1)}
        product1={product1}
        product2={product2}
        setStep={setStep}
        step={step} />
      
      {/* STEP 2: IS GIFT? */}
      <Step2IsGift
        data={data}
        isShow={(step === 2)} 
        setStep={setStep} />

      {/* STEP 3: HAVE ACCOUNT? */}
      {/* OPTIONS: YES, NO, DON'T KNOW */}
      <Step3IsHaveAccount
        code={code}
        isShow={(step === 3)} 
        setStep={setStep} />

      {/* STEP 10: EXISTING ACCOUNT PATH */}
      <Step10ExistingAccount 
        email={(data) ? data.email : null} 
        isShow={(step === 10)}
        setEmailSubmitted={setEmailSubmitted}
        setStep={setStep}
        setVhxId={setVhxId}
        step={step} />

      <Step11NotFound
        emailSubmitted={emailSubmitted}
        isShow={(step === 11)}
        setStep={setStep}
        step={step} />
      
      {/* STEP 12: EXISTING ACCOUNT FOUND */}
      <Step12AccountFound
        code={code}
        //email={email}
        emailSubmitted={emailSubmitted}
        isShow={(step === 12)}
        setStep={setStep}
        step={step} 
        vhxId={vhxId} />

      {/* STEP 20: NEW ACCOUNT PATH */}
      <Step20NewAccount
        code={code}
        emailSubmitted={emailSubmitted}
        isShow={(step === 20)}
        setEmailSubmitted={setEmailSubmitted}
        setStep={setStep}
        step={step} />

      {/* STEP 21: CONFIRM CREATE (FROM STEP 11) */}
      <Step21ConfirmCreate
        code={code}
        data={data}
        //email={email}
        emailSubmitted={emailSubmitted}
        isShow={(step === 21)}
        setStep={setStep}
        step={step} />

      {/* STEP 22: CONFIRM CREATE (FROM STEP 20) */}
      <Step22ConfirmCreate
        code={code}
        data={data}
        //email={email}
        emailSubmitted={emailSubmitted}
        isShow={(step === 22)}
        setStep={setStep}
        step={step} />

      <Step30Success
        emailSubmitted={emailSubmitted}
        isShow={(step === 30)}
        step={step} />

      {/* STEP 31: MULTI VHX ACCOUNTS FOUND/UNCAUGHT ERROR: SEND TICKET */}
      <Step31ErrorRedeem
        code={code}
        emailSubmitted={emailSubmitted}
        isShow={(step === 31)} />

      <Footer 
        handleStartOver={handleStartOver}
        setStep={setStep} />
    </div>
  );
}
