import {useState} from "react";

export default function Step11NotFound({code, emailSubmitted, isShow, setStep}) {
  const [isLoading, ] = useState(false);

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          ...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step"}>
          We could not find an existing account with the email address {emailSubmitted}<br/><br/>
          <button className="button-blue" onClick={()=>setStep(10)}>Change email address</button><br/><br/>
          <button className="button-blue" onClick={()=>setStep(21)}>Create new account for {emailSubmitted}</button><br/><br/>
          <a className="button-blue" href={`mailto:kickstarter@mst3k.com?subject=I forget if I have a Gizmoplex account of what email address I used!&body=I can't remember if I se up a Gizmoplex account. My Gizmoplex reward code is ${code}. Please help!`}>Contact Support</a> 
        </div>
      );
    }
  } else {
    return (<></>);
  }
}