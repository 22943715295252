import {useState} from "react";

export default function Step3IsHaveAccount({code, isShow, setStep}) {
  const [isRemember, setIsRemember] = useState(true);
  const [isLoading, ] = useState(false);

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          ...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step"}>
          <div className={isRemember ? "" : "display-none"}>
            To redeem this reward, you'll need a Gizmoplex account.<br/>
            <h3>Do you already have a Gizmoplex or VHX account?</h3><br/>
            <button className="button-blue" onClick={()=>setStep(10)}>Yes</button>
            <button className="button-blue" onClick={()=>setStep(20)}>No</button>
            <button className="button-blue" onClick={()=>setIsRemember(false)}>Don't Know</button><br/>
            <br/>
            <sub>If you don't remember what email address your Gizmoplex/VHX account uses, or whether you have one, click "Don't Know"<br/>
            <b>Please note:</b>Your Gizmoplex or VHX account is <i>not</i> the same as your<br/>
            Kickstarter or BackerKit account, and may have been set up using a different email address.</sub>
          </div>
          <div className={!isRemember ? "" : "display-none"}>
            That's okay! If you want, we can check a few email addresses for you.<br/>
            <br/>
            On the next screen, just let us know what email address you might have used,<br/>
            and we'll let you know whether there's a matching account.<br/>
            <br/>
            If there is, you can use that account to redeem your reward.<br/>
            <br/>
            If there's not, you can try other email addresses you might have used,<br/>
            or just create a new account using your current email. It's easy!<br/>
            <br/>
            <button className="button-blue" onClick={()=>setStep(10)}>Check an Email Address</button>
            <a className="button-blue" href={`https://docs.google.com/forms/d/e/1FAIpQLScxY2HGfbt4cdLR3iVR4ur9ygthqXjkGizEE2OShFkI3XT_tg/viewform?usp=pp_url&entry.2125505961=${code}`} rel="noopenner noreferrer" target="_blank">Contact Support</a>
            <a className="button-blue display-none" href={`mailto:kickstarter@mst3k.com?subject=I forget if I have a Gizmoplex account of what email address I used!&body=I can't remember if I set up a Gizmoplex account. My Gizmoplex reward code is ${code}. Please help!`}>Contact Support</a> 
          </div>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}