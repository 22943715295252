import logo from './images/logo-gizmoplex25.webp';
import './styles/App.css';

import {auth, firebaseApp} from "./Firebase";
import AuthContext from './AuthContext';
import {ErrorBoundary} from './Bugsnag';
import {getAnalytics, setUserProperties} from "firebase/analytics";
import {useEffect} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';

function App() {
  const [user, , ] = useAuthState(auth); // user, loading, error

  // Init analytics
  useEffect(() => {
    try {
      if ( user && !user.email ) {
        const analytics = getAnalytics(firebaseApp);
        setUserProperties(analytics, { anon_uid: user.uid });
      }
    } catch(error) {}
  },[user])

  // Set page title
  useEffect(() => {
    document.title = "Gizmoplex Backer Redemption";  
  });

  return (
    <ErrorBoundary>
      <div className="App">
        <Router>
          <img src={logo} className="logo" alt="logo" />
          <AuthContext />
          <Routes>
            <Route exact path="/">
            </Route>
            <Route exact path="*">
              <></>
            </Route>
          </Routes>
        </Router>
      </div>
    </ErrorBoundary>
  );
}

export default App;
