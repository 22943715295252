import { auth, functions } from './Firebase';
import {httpsCallable} from "firebase/functions";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";

export default function Step20NewAccount({code, emailSubmitted, isShow, setEmailSubmitted, setStep, step}) {
  const [isAccountExists, setIsAccountExists] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(valuesEmail) {
    // Set loading
    setIsLoading(true);

    // Check if email address exists in vhx
    const user = auth.currentUser;
    const idToken = await user.getIdToken();
    const vhxQueryCustomerEmail = httpsCallable(functions, 'vhxQueryCustomerEmail');
    if ( idToken ) {
      const results = await vhxQueryCustomerEmail({
        "idToken": idToken,
        "email": valuesEmail,
      });
      if (results) {
        const data = results.data;
        setEmailSubmitted(valuesEmail);

        if ( data.count > 0 ) {
          // Error account already exists
          setIsAccountExists(true);
        } else if ( data.count === 0 ) {
          // If 0 results: "is this right email/account?" "can we create?"
          setIsAccountExists(false);
        }
        
        // Set not loading
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    setIsAccountExists(null);
  },[])

  useEffect(() => {
    setIsAccountExists(null);
  },[step])

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          One moment. We're making sure that address is available. <div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step"}>
          <Formik
            enableReinitialize={true} // enableReinitialize prop resets form only if initialValues is changed
            initialValues={{ 
              email: "",
            }}
            // Validation rules
            validate={values => {
              const errors = {};
              // EMAIL
              if ( !values.email ) {
                // Email is required
                errors.email = 'Required';
              } else if (
                // Validate email
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}

            // Submit actions
            onSubmit={(values, { resetForm, setSubmitting }) => {
                setSubmitting(true);

                setTimeout(() => {
                  // Submit form
                  handleSubmit(values.email);

                  // Re-enable buttons
                  setSubmitting(false);
                }, 400);
            }}
          >
            {({ isSubmitting, resetForm }) => (
              <>
                <Form className={(isAccountExists === null) ? "" : "display-none"}>
                  Let's set up your Gizmoplex account!<br/>
                  <b>What email address should we use?</b><br/><br/>
                  <div className={"form-card"}>
                    <label htmlFor="email"></label>
                    <Field className="form-input" id="email"name="email" type="email" />
                    <ErrorMessage className="error-message" component="div" name="email" />
                  </div><br/>
                  <button className="button-blue">Continue</button>
                </Form>
                <div className={(isAccountExists === true) ? "" : "display-none"}>
                  <br/> 
                  Hold up! An account already exists with email address {emailSubmitted}.<br/>
                  Is this your account?<br/><br/>
                  <button className="button-blue" onClick={()=>setStep(12)}>Yes! Use this account.</button><br/><br/>
                  <button className="button-blue" onClick={()=>setIsAccountExists(null)}>Yes, but I want to create a different account.</button><br/><br/>
                  <a className="button-blue" href={`mailto:kickstarter@mst3k.com?subject=An account already exists with my email address&body=An account already exists with my email address and I don't remember creating it. My Gizmoplex reward code is ${code} and the email address is ${emailSubmitted}. Please help!`}>No. Contact Support</a> 
                </div>
                <div className={(isAccountExists === false && isAccountExists !== null) ? "" : "display-none"}>
                  Great! That email address is available.<br/><br/>
                  <button className="button-blue" onClick={()=>setStep(22)}>Continue</button>
                </div>
              </>
            )}
          </Formik>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}
    