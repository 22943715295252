import { useState } from "react";

export default function Step31ErrorRedeem({code, emailSubmitted, isShow}) {
  const [isLoading, ] = useState(false);

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          ...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step email-error"}>
          We're sorry. There was an error redeeming your code.<br/>
          Please use the button below to get help from our support team!<br/>
          <br/>
          <a 
            className="button-blue" 
            href={
              `mailto:gizmoplex@mst3k.com?subject=Can’t redeem code for ${code} : ${emailSubmitted}`
            }>Contact Support</a>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}
