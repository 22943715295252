import {initializeApp} from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {firebaseConfigEmultaor, firebaseConfigLive, firebaseConfigStaging} from './firebaseConfig';
import {firebaseSiteKeyEmulator, firebaseSiteKeyLive, firebaseSiteKeyStaging} from './firebaseConfig';

// INIT FIREBASE
let isEmulator;
let isStaging;
if ( window.location.hostname === "localhost" ) {
  isEmulator = true;
} else {
  isEmulator = false;
  if ( window.location.hostname === "promos-gogogadget.web.app") {
    isStaging = true;
  } else {
    isStaging = false;
  }
}

var firebaseApp;
var siteKey;
if ( isEmulator) {
  //  EMULATOR SETTING
  var firebaseAppEmulator = initializeApp(firebaseConfigEmultaor, "emulator");
  firebaseApp = firebaseAppEmulator;
  siteKey = firebaseSiteKeyEmulator;
  // set debug token true
  firebaseApp.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else if ( isStaging ) {
  // CLOUD STAGING SETTINGS
  var firebaseAppStaging = initializeApp(firebaseConfigStaging, "staging");
  firebaseApp = firebaseAppStaging;
  siteKey = firebaseSiteKeyStaging;
} else {
  // CLOUD LIVE SETTINGS
  var firebaseAppLive = initializeApp(firebaseConfigLive, "live");
  firebaseApp = firebaseAppLive; 
  siteKey = firebaseSiteKeyLive;
}

// ACTIVATE APPCHECK
if ( !isEmulator ) {
  // const appCheck = 
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(siteKey),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
} else {
  /*
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(siteKey),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
  */
}

// FIREBASE PRODUCTS
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

if ( isEmulator ) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8082);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export {
  auth,
  db,
  firebaseApp,
  functions,
};
