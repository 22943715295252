import {auth, functions} from "./Firebase";
import {signInAnonymously} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import useQuery from "./hooks/useQuery";
import {useEffect, useState} from "react";
import {useAuthState} from 'react-firebase-hooks/auth';

export default function StepNeg1ValidLink({code, data, handleStartOver, isLinkValid, isShow, setCode, setData, setIsLinkValid, setStep, step}) {
  const [isIncompleteUrl, setIsIncompleteUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery(); // query string parameters
  const [user, loading, ] = useAuthState(auth); // user, loading,

  function handleClick() {
    verifyUniqueLink();
  }

  async function verifyUniqueLink() {
    // Set loading
    setIsLoading(true);

    // Check that url contains code
    const _code = query.get("code");
    if ( _code && _code !== code ) {
      setCode(_code);
    } else if ( !_code ) {
      setIsIncompleteUrl(true);
    }

    // Check that user is logged in
    let _user;
    if ( !user ) {
      _user = await signInAnonymously(auth);
    } else {
      _user = user;
    }

    if ( _user && _code ) {
      const idToken = await _user.getIdToken(false);
      const verifyBackerRedemptionLink = httpsCallable(functions, 'verifyBackerRedemptionLink');
      const results = await verifyBackerRedemptionLink({
        "idToken": idToken,
        "code": _code,
      });

      if ( results ) {
        // results.data = null if code not found
        if ( results.data ) { 
          // Set state link valid
          setIsLinkValid(true);
          
          // Set state with all returned data
          setData(results.data);

          setTimeout(() => {setStep(0)}, 500);
        } else {
          // If !results.data set state url not valid and clear data
          setIsLinkValid(false);
          setData(null);
          setTimeout(() => {setIsLoading(false)}, 500);
        }
      } else {
        // If !results set state url not valid and clear data
        setIsLinkValid(false);
        setData(null);
        setTimeout(() => {setIsLoading(false)}, 500);
      }
    }
  }

  // Reset 
  useEffect(() => {
    setIsIncompleteUrl(false);
  },[])
  

  if ( isShow ) {
    if ( isLoading || loading ) {
      // happens on click and when signing in 
      return (
        <div className="step">
          <div className="spinner-inline" />
        </div>
      )
    } else {
      if ( isIncompleteUrl ) {
        return (
          <div className={"step"}>
            Your link is missing code parameter.<br/>
            Please check that you copy pasted the complete url.
          </div>
        )
      } else if ( isLinkValid === false && isLinkValid !== null ) {
        return (
          <div className={"step"}>
            Hmm. We’re having trouble finding your reward code.<br/>
            <br/>
            Please try <button className="button-link" onClick={handleStartOver}>starting over</button> to see if that solves the problem.<br/>
            If that doesn’t work, please try redeeming the code in another browser, or on another device if possible.<br/>
            And if that doesn’t work, please use the button below to get help from our support team!<br/>
            <br/>
            <a className="button-blue" href={`mailto:kickstarter@mst3k.com?subject=HELP! Gizmoplex Code ${code} says that it's not valid&subject=Problem redeeming Gizmoplex Code ${code}`}>Contact Support</a>
          </div>
        );
      } else {
        return (
          <div className={"step"}>
            <button className="button-blue" onClick={handleClick}>Click To Continue</button>
          </div>
        );
      }
    }
  } else {
    return (<></>)
  }
}
