import { useState } from "react";

export default function Step30Success({emailSubmitted, isShow}) {
  const [isLoading, ] = useState(false);

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          ...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step success"}>
          Great! This reward has now been<br/>
          redeemed for <b>{emailSubmitted}</b><br/>
          <br/>
          You'll get an email soon<br/>
          with a link to your reward!<br/>
          <br/>
          You can also find this reward<br/>
          in your <a href="https://www.gizmoplex.com/settings/purchases" rel="noopenner noreferrer" target="_blank">Gizmoplex Library.</a>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}
