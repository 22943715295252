import {auth, functions} from './Firebase';
import {httpsCallable} from "firebase/functions";
import {useState} from "react";

export default function Step12AccountFound({code, email, emailSubmitted, isShow, setStep, vhxId}) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    // Set loading
    setIsLoading(true);
    const user = auth.currentUser;
    const idToken = await user.getIdToken();
    const redeemCode = httpsCallable(functions, 'vhxRedeemPromoCode');
    if ( idToken ) {
      const results = await redeemCode({
        "idToken": idToken,
        "code": code,
        "email": email,
        "emailSubmitted": emailSubmitted,
        "vhxId": vhxId,
      });
      if ( results ) {
        if ( results.data && results.data.success ) {
          setStep(30); // success
        } else {
          // const error = results.data.error || "";
          setStep(31);
        }
      }
    }
  }

  if ( isShow ) {
    if ( isLoading ) {
      return (
        <div className="step">
          Applying promo...<div className="spinner-inline" />
        </div>
      )
    } else {
      return (
        <div className={"step email-found"}>
          Great! We found an existing account for <b>{emailSubmitted}</b><br/>
          Do you want to claim your reward(s) with this account now?<br/>
          <br/>
          <button className="button-blue" onClick={handleSubmit}>Yes, use this account!</button>
          <button className="button-blue" onClick={()=>setStep(10)}>No, use a different account.</button>
        </div>
      );
    }
  } else {
    return (<></>);
  }
}
    