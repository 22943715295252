// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfigEmultaor = {
  apiKey: "AIzaSyCdPgEAGcL3aMQ4_L5OAA5NFOLwFjk_URg",
  authDomain: "gogogadget-v1.firebaseapp.com",
  projectId: "gogogadget-v1",
  storageBucket: "gogogadget-v1.appspot.com",
  messagingSenderId: "525768346067",
  appId: "1:525768346067:web:33cd0a009d569993fbc726",
  measurementId: "G-ES0T7QY5P4"
};

export const firebaseConfigStaging = {
  apiKey: "AIzaSyBuJvbtyQU6s50xra68PTDyBQkmTLngLuw",
  authDomain: "gogogadget-v1.firebaseapp.com",
  projectId: "gogogadget-v1",
  storageBucket: "gogogadget-v1.appspot.com",
  messagingSenderId: "525768346067",
  appId: "1:525768346067:web:33cd0a009d569993fbc726",
  measurementId: "G-ES0T7QY5P4"
};

export const firebaseConfigLive = {
  apiKey: "AIzaSyBz197AM4GaFWDl35bC8QuOe0RbzefEG7w",
  authDomain: "coral-marker-323818.firebaseapp.com",
  projectId: "coral-marker-323818",
  storageBucket: "coral-marker-323818.appspot.com",
  messagingSenderId: "83587848354",
  appId: "1:83587848354:web:ce57427b5eaf3197b95ffb",
  measurementId: "G-N89R187PFQ"
};

// recaptcha keys
export const firebaseSiteKeyEmulator = 'a5906270-5c2a-4e90-afd0-2f540ef47c7c';
export const firebaseSiteKeyStaging = '6LeO5eMeAAAAACXW2NR9QIlRrhHkCvM5jvXrARAE';
export const firebaseSiteKeyLive = '6LeO5eMeAAAAACXW2NR9QIlRrhHkCvM5jvXrARAE';
